import React, { useEffect } from "react";
import "./footer.scss";
import DownloadButton from "../downloadButton/DownloadButton.jsx";
import Google from "../../assets/imgs/google-play.png";
import Apple from "../../assets/imgs/apple.png";
import Facebook from "../../assets/imgs/facebook.png";
import Instagram from "../../assets/imgs/instagram.png";
import Photo from "../../assets/imgs/[fpdl.in]_abu-dhabi-buildings-skyline-from-sea_70898-2538_large.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);
  let date = new Date();
  let year = date.getFullYear();
  return (
    <section className='footer'>
      <div className='gradiant'></div>
      <div className='footer-container container'>
        <div className='texts'>
          <h1 data-aos='fade-up'>Ready to go out?!</h1>
          <h4 data-aos='fade-up'>Download Step Out</h4>
          <p data-aos='fade-up'>
            share it with your family/friends and choose clearly where to go
          </p>
        </div>
        <div data-aos='fade-up' className='buttons'>
          <DownloadButton
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.estepOut.stepOut&pli=1",
                "_blank"
              );
            }}
            value={false}
            logo={Google}
            text='Play Store'
          />
          <DownloadButton
            onClick={() => {
              window.open(
                "https://apps.apple.com/eg/app/step-out-where-to-go/id6476232515",
                "_blank"
              );
            }}
            value={false}
            logo={Apple}
            text='App Store'
          />
        </div>
        <div className='links'>
          <div className='iconsLinks'>
            <Link to='/'>
              <img src={Facebook} alt='' clLinkssName='icons' />
            </Link>
            <Link to='/'>
              <img src={Instagram} alt='' className='icons' />
            </Link>
          </div>
        </div>
      </div>
      <div className='copyrights'>
        &copy; Copyrights {year} E Brand Agency All rights reserved.
      </div>
      <div data-aos='fade-up' className='layout'>
        <img src={Photo} alt='' />
      </div>
    </section>
  );
};

export default Footer;
