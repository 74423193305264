import React, { useEffect } from "react";
import "./section4.scss";
import Icon1 from "../../assets/imgs/2eb16c61-e680-4268-8bb9-49f080c6d419 1.png";
import Icon2 from "../../assets/imgs/72705f2e-bf94-4e48-afa1-ce6bd0e2cd76 1.png";
import Icon3 from "../../assets/imgs/c0b893cd-4183-48fa-be75-7dfa36193244 1.png";
import Photo from "../../assets/imgs/[Mockup] iPhone 15.png";
import AOS from "aos";
import "aos/dist/aos.css";
const Section4 = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='section4'>
      <div className='heading'>
        <h1>How to use Step out</h1>
        <p>
          With easy and simple steps, you can know all the places and news about
          Bahrain
        </p>
        <div className='section4-container container'>
          <div className='left'>
            <ul>
              <li data-aos='fade-right'>
                <div className='item'>
                  <img src={Icon1} alt='' />
                  <div className='itemTexts'>
                    <h2>Download the app</h2>
                    <p>
                      To download the application immediately, go to Google Play
                      Store and Apple App Store
                    </p>
                  </div>
                </div>
              </li>
              <li data-aos='fade-right'>
                <div className='item'>
                  <img src={Icon2} alt='' />
                  <div className='itemTexts'>
                    <h2>Sign up and create an account</h2>
                    <p>
                      Open the application and follow the steps to register a
                      new account ... all your details will be safe and not to
                      be shared with any other party
                    </p>
                  </div>
                </div>
              </li>
              <li data-aos='fade-right'>
                <div className='item'>
                  <img src={Icon3} alt='' />
                  <div className='itemTexts'>
                    <h2>Discover our services</h2>
                    <p>Choose from category what are you looking for</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div data-aos='flip-right' className='right'>
            <img src={Photo} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
