import React, { useEffect } from "react";
import "./section3.scss";
import Phone1 from "../../assets/imgs/double-screens.png";
import AOS from "aos";
import "aos/dist/aos.css";
const Section3 = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='section3'>
      <div className='section3-container container'>
        <div className='left'>
          <ul>
            <li data-aos='fade-right'>
              <p className='elment'>
                Check category you search for and note that some venues fall
                under more than one category.
              </p>
            </li>
            <li data-aos='fade-right'>
              <p className='elment'>
                Use different filters created for your convenience.
              </p>
            </li>
            <li data-aos='fade-right'>
              <p className='elment'>
                Use hashtags to narrow your search results and compare the
                venues of the same features.
              </p>
            </li>
            <li data-aos='fade-right'>
              <p className='elment'>
                Venues arranged by as the nearest to your place.
              </p>
            </li>
            <li data-aos='fade-right'>
              <p className='elment'>
                You may search for venues in specific area.{" "}
              </p>
            </li>
            <li data-aos='fade-right'>
              <p className='elment'>
                We’re here for your convenience so don’t hesitate to contact us
                if you’ve any suggestions for better service …. Your feedback
                will be definitely considered.{" "}
              </p>
            </li>
          </ul>
        </div>
        <div data-aos='fade-left' className='right'>
          <img src={Phone1} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Section3;
