import React from "react";
import "./aboutUs.scss";
import Section1AboutUs from "../../components/section1-aboutus/Section1AboutUs";
import Section2AboutUs from "../../components/section2-aboutus/Section2AboutUs";
const AboutUs = () => {
  return (
    <div className='aboutus'>
      <Section1AboutUs />
      <Section2AboutUs />
    </div>
  );
};

export default AboutUs;
