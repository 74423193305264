import React from "react";
import "./home.scss";
import Section1 from "../../components/section1/Section1";
import SliderImage from "../../components/sliderImage/SliderImage";
import Section3 from "../../components/section3/Section3";
import Section4 from "../../components/section4/Section4";
import Footer from "../../components/Footer/Footer";
const Home = () => {
  return (
    <div className='home'>
      <Section1 />
      <SliderImage />
      <Section3 />
      <Section4 />
      <Footer />
    </div>
  );
};

export default Home;
