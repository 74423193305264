import React, { useEffect } from "react";
import "./section1.scss";
import Navbar from "../navbar/Navbar.jsx";
import Mobile from "../../assets/imgs/Group 452236.png";
import DownloadButton from "../downloadButton/DownloadButton";
import Google from "../../assets/imgs/google-play.png";
import Apple from "../../assets/imgs/apple.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
const Section1 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='section1'>
      <Navbar value={false} />
      <div className='section1-container container'>
        <div data-aos='fade-right' className='left'>
          <div className='content'>
            <div className='texts'>
              <h1>When you plan to go out need to know where to go ?!! </h1>
              <p>
                Step Out is your guide,
                <br /> The new era in exploring new venues or those around you
              </p>
            </div>
            <div className='buttons'>
              <DownloadButton
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.estepOut.stepOut&pli=1",
                    "_blank"
                  );
                }}
                value={true}
                logo={Google}
                text='Play Store'
              />
              <DownloadButton
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/eg/app/step-out-where-to-go/id6476232515",
                    "_blank"
                  );
                }}
                value={true}
                logo={Apple}
                text='App Store'
              />
            </div>
          </div>
        </div>
        <div data-aos='fade-left' className='right'>
          <img data-aos='flip-right' src={Mobile} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Section1;
