import React from "react";
import "./sliderImage.scss";
import { Carousel } from "../slider/index.js";
import img1 from "../../assets/imgs/1.png";
import img2 from "../../assets/imgs/2.png";
import img3 from "../../assets/imgs/3.png";
let slides = [
  <img src={img2} alt='3' />,
  <img src={img1} alt='2' />,
  <img src={img3} alt='4' />,
];
const callback = function (index) {
  return;
};
const SliderImage = () => {
  return (
    <div id='carouselExample' className='carousel slide'>
      <div className='sliderImageConatiner container'>
        <Carousel
          slides={slides}
          autoplay={true}
          interval={3000}
          arrows={false}
          oncallback={callback}
        />
      </div>
    </div>
  );
};

export default SliderImage;
