import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import ContactUs from "./pages/contactUs/ContactUs";
import AboutUs from "./pages/aboutAs/AboutUs";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/aboutus' element={<AboutUs />} />
      <Route path='/contactus' element={<ContactUs />} />
    </Routes>
  );
}

export default App;
