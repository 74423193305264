import React, { useEffect } from "react";
import "./section2AboutUs.scss";
import id from "../../assets/imgs/id.png";
import Characters from "../../assets/imgs/charactres1.png";
import HomePhoto from "../../assets/imgs/mobile (2).png";
import Photo from "../../assets/imgs/[fpdl.in]_abu-dhabi-buildings-skyline-from-sea_70898-2538_large.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const Section2AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='section2-aboutus'>
      <div className='gradiant'></div>
      <div className='section2-aboutus-container container'>
        <div className='top'>
          <div className='left'>
            <img data-aos='flip-right' src={id} alt='' />
            <h3 data-aos='fade-right'>Vision</h3>
            {/* <p data-aos='fade-right'>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p> */}
            <p data-aos='fade-right'>
              Step Out is dedicated to delivering effective efficient respectful
              public service with integrity and accountability using innovative
              methods.
            </p>
          </div>
          <div className='right'>
            <img data-aos='flip-left' src={HomePhoto} alt='' />
          </div>
        </div>{" "}
        <div className='bottom'>
          <div className='left'>
            <img data-aos='fade-right' src={Characters} alt='' />
          </div>
          <div className='right'>
            <h3 data-aos='fade-left'>Mission</h3>
            {/* <p data-aos='fade-left'>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p> */}
            <p data-aos='fade-left'>
              To make Step Out the application of choice to guide you while
              going out providing full and specific characteristics of the
              places you want to spend time in
            </p>
          </div>
        </div>
      </div>
      <div data-aos='fade-up' className='layout'>
        <img src={Photo} alt='' />
      </div>
    </section>
  );
};

export default Section2AboutUs;
