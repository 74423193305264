import React, { useRef, useState } from "react";
import Logo from "../../assets/imgs/f step with white.png";
import "./navbar.scss";
import { Link } from "react-router-dom";
import menu from "../../assets/imgs/menu (1).png";
const Navbar = ({ value }) => {
  const [isScrolled, setISScrolled] = useState(false);
  const menuRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  window.onscroll = () => {
    setISScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };
  const handleClick = (value) => {
    setOpenMenu(value);
    if (openMenu) {
      menuRef.current.style.display = "flex";
    } else {
      menuRef.current.style.display = "none";
    }
  };
  console.log(isScrolled);
  return (
    <section
      className={
        value ? "navbar scrolled" : isScrolled ? "navbar scrolled" : "navbar"
      }
    >
      {" "}
      <div className='navbar-container container'>
        <div className='logo'>
          <Link to='/'>
            <img src={Logo} alt='' />
          </Link>
        </div>
        <div className='center'>
          <ul ref={menuRef}>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/aboutus'> About Us</Link>
            </li>
            <li>
              {" "}
              <Link to='/contactus'> Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className='right'>
          <button
            onClick={() => {
              handleClick(!openMenu);
            }}
          >
            <img src={menu} alt='' />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
